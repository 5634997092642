<template>
    <div class="brand-page">
        <div class="section intro-section">
            <h2>相关人物</h2>
            <img
            src="/people/广.png"
            mode="scaleToFill"
        />
        </div>
    </div>
</template>

<script>
export default {
    name: 'Guang'
}
</script>

<style scoped>
/* Basic container styles */
.brand-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: #faf8f5;
    /* Light background color similar to the example */
}

/* Intro Section Styles */
.intro-section {
    text-align: center;
    margin-bottom: 40px;
}

.intro-section h2 {
    font-size: 2.0em;
    font-weight: bold;
    color: #4a4a4a;
    margin-bottom: 20px;
}

.intro-section p {
    font-size: 1.0em;
    line-height: 1.8;
    color: #4a4a4a;
}

/* Spirit Section Styles */
.spirit-section {
    text-align: center;
}

.spirit-section h3 {
    font-size: 2em;
    font-weight: bold;
    color: #4a4a4a;
    margin-bottom: 20px;
}

.spirit-section p {
    font-size: 1.2em;
    line-height: 1.8;
    color: #4a4a4a;
    margin-bottom: 20px;
}

.learn-more {
    display: inline-block;
    margin-top: 20px;
    font-size: 1.2em;
    color: #0073e6;
    text-decoration: none;
    border-bottom: 1px solid #0073e6;
}

.image-container {
    margin-top: 200px;
    text-align: center;
}

.image-container img {
    max-width: 100%;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
    .brand-page {
        padding: 10px;
    }

    .intro-section h2,
    .spirit-section h3 {
        font-size: 1.8em;
    }

    .intro-section p,
    .spirit-section p {
        font-size: 1em;
    }

    .learn-more {
        font-size: 1em;
    }
}

.page-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px;
    background-color: #f8f3ec;
    /* Set background color similar to the image */
}

.content-section {
    max-width: 50%;
    /* The content section takes up 50% of the width */
}

.content-section h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
}

.content-section p {
    font-size: 1.2rem;
    line-height: 1.6;
    margin-bottom: 20px;
}

.learn-more {
    font-size: 1.1rem;
    color: #000;
    text-decoration: none;
    border-bottom: 1px solid #000;
    /* Underline effect */
    padding-bottom: 2px;
}

.learn-more:hover {
    color: #555;
    /* Darken the text color on hover */
    border-color: #555;
    /* Change underline color on hover */
}

.image-section {
    max-width: 45%;
    /* The image section takes up 45% of the width */
}

.image-section img {
    width: 100%;
    height: auto;
    object-fit: cover;
}
</style>